import React, { useEffect } from "react";
import $ from "jquery";
import "magnific-popup";

const TrendingGame = () => {
  useEffect(() => {
    $(".popup-video").magnificPopup({
      type: "iframe",
    });
  }, []);

  return (
    <section className="trending-gamepay-area" id="media">
      <div className="container padding__">
        <div className="row align-items-center mb-30">
          <div className="col-sm-6">
            <div className="hf-section-title">
              <h4 className="title" style={{ lineHeight: 1.3 }}>
                Check Out The Audio Book on YouTube!
              </h4>
            </div>
          </div>
          <div className="col-sm-6 d-none d-sm-block">
            <div className="section-btn">
              <a
                href="https://pmgpublishing.com/shop/ols/products/battle-breakin-crews-the-original-breakers"
                className="border-btn"
              >
                Buy the Book
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="trending-gameplay-item mb-50">
              <div className="gameplay-thumb">
                <a
                  href="https://www.youtube.com/watch?v=-E4iasJPjPc"
                  className="popup-video"
                >
                  <img
                    src="assets/img/icon/w_play.png"
                    alt=""
                    className="play"
                  />
                  <img
                    style={{
                      maxHeight: "250px",
                      objectFit: "cover",
                      borderRadius: "12px",
                    }}
                    src="/images/ep1.webp"
                    alt=""
                  />
                </a>
                <div className="treand-gameplay-overlay">
                  <ul>
                    <li className="duration">01 :45</li>
                    <li className="quality">hd</li>
                  </ul>
                </div>
              </div>
              <div className="d-block d-sm-flex align-items-start">
                <div className="gameplay-content">
                  <h5 className="title">
                    <a href="/#">Listen to the Audio Version - Episode 001</a>
                  </h5>
                  <div className="gameplay-meta">
                    <ul>
                      <li style={{ textTransform: "capitalize" }}>
                        Welcome to episode one of the epic adventures of the
                        7Forces crew as they gear up for the Battle of the Year
                        in NYC! Click on the button below to purchase the book
                        and experience the adventure in full effect.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="gameplay-status">
                  <span>lives</span>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="trending-gameplay-item mb-50">
              <div className="gameplay-thumb">
                <a
                  href="https://www.youtube.com/watch?v=lDzqvfrPLEE"
                  className="popup-video"
                >
                  <img
                    src="assets/img/icon/w_play.png"
                    alt=""
                    className="play"
                  />
                  <img
                    style={{
                      maxHeight: "250px",
                      objectFit: "cover",
                      borderRadius: "12px",
                    }}
                    src="/images/ep2.webp"
                    alt=""
                  />
                </a>
                <div className="treand-gameplay-overlay">
                  <ul>
                    <li className="duration">01 :45</li>
                    <li className="quality">hd</li>
                  </ul>
                </div>
              </div>
              <div className="d-block d-sm-flex align-items-start">
                <div className="gameplay-content">
                  <h5 className="title">
                    <a href="/#">
                      The Original Breakers - Episode 002 (Audio Version)
                    </a>
                  </h5>
                  <div className="gameplay-meta">
                    <ul>
                      <li style={{ textTransform: "capitalize" }}>
                        {" "}
                        In this episode, B-Boy Helado loses his cool on the
                        floor with a rival crew member.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="trending-gameplay-item mb-50">
              <div className="gameplay-thumb">
                <a
                  href="https://www.youtube.com/watch?v=thfsUBSadFQ"
                  className="popup-video"
                >
                  <img
                    src="assets/img/icon/w_play.png"
                    alt=""
                    className="play"
                  />
                  <img
                    style={{
                      maxHeight: "250px",
                      objectFit: "cover",
                      borderRadius: "12px",
                    }}
                    src="/images/ep3.webp"
                    alt=""
                  />
                </a>
                <div className="treand-gameplay-overlay">
                  <ul>
                    <li className="duration">01 :45</li>
                    <li className="quality">hd</li>
                  </ul>
                </div>
              </div>
              <div className="d-block d-sm-flex align-items-start">
                <div className="gameplay-content">
                  <h5 className="title">
                    <a href="/#">
                      The Original Breakers - Episode 003 - (Audio Version)
                    </a>
                  </h5>
                  <div className="gameplay-meta">
                    <ul>
                      <li style={{ textTransform: "capitalize" }}>
                        In this episode, the 7Forces Crew gives 100% as the
                        underdogs in the semi-finals!{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="trending-gameplay-item mb-50">
              <div className="gameplay-thumb">
                <a
                  href="https://www.youtube.com/watch?v=_Bp10Fm_Rxw"
                  className="popup-video"
                >
                  <img
                    src="assets/img/icon/w_play.png"
                    alt=""
                    className="play"
                  />
                  <img
                    style={{
                      maxHeight: "250px",
                      objectFit: "cover",
                      borderRadius: "12px",
                    }}
                    src="/images/ep4.webp"
                    alt=""
                  />
                </a>
                <div className="treand-gameplay-overlay">
                  <ul>
                    <li className="duration">01 :45</li>
                    <li className="quality">hd</li>
                  </ul>
                </div>
              </div>
              <div className="d-block d-sm-flex align-items-start">
                <div className="gameplay-content">
                  <h5 className="title">
                    <a href="/#">
                      Battle Breakin' Crews - Superhero Break Dancers
                    </a>
                  </h5>
                  <div className="gameplay-meta">
                    <ul>
                      {/* <li>35k views</li>
                      <li>January 25, 2022</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrendingGame;
