import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { FaTiktok } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";
function FooterThree() {
  return (
    <footer>
      {/* <div className="footer-top footer-bg third-footer-bg">
        <div className="container custom-container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="footer-widget mb-50">
                <div className="logo mb-10">
                  <Link to="/index-3">
                    <img
                      src={logo}
                      style={{
                        maxWidth: "130px",
                        // marginLeft: "-32px",
                        width: "100%",
                      }}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="fw-text">
                  <p>Super Dope Breakdancers with Powers. Join the Journey.</p>
                  <div className="fw-social">
                    <ul>
                      <li>
                        <a
                          href=" https://www.youtube.com/@battlebreakincrews4784/featured"
                          className="youtube"
                        >
                          <AiFillYoutube />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.tiktok.com/@battlebreakincrews?lang=en"
                          className="tiktok"
                        >
                          <FaTiktok />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-5">
              <div className="footer-widget text-right mb-50">
                <div className="fw-title mb-35"></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="copyright-wrap third-copyright-wrap">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-8 col-md-6">
              <div className="copyright-text">
                <p>
                  Copyright © 2022{" "}
                  <Link to="/" style={{ color: "#d99a03" }}>
                    Battle Breakin' Crews
                  </Link>{" "}
                  All Rights Reserved. &nbsp;&nbsp;
                  <span className="footer__span">
                    <b>Email:</b> hello@battlebreakincrews.com
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-none d-md-block">
              <div className="fw-quick-link">
                <ul
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                    gap: "38px",
                  }}
                >
                  <li>
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#story">About Us</a>
                  </li>
                  <li>
                    <a href="#media">Media</a>
                  </li>
                  <li>
                    <a href="#collection">Collection</a>
                  </li>
                </ul>
              </div>
              <div className="payment-method-img text-right">
                {/* <img src="assets/img/images/card_img.png" alt="img" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterThree;
