import React from "react";
import Slider from "react-slick";

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fa fa-angle-right"></i>
    </button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fa fa-angle-left"></i>
    </button>
  );
}

function TestimonialArea() {
  const settings = {
    dots: false,
    infinite: true,
    margin: 0,
    speed: 1000,
    nav: true,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <div className="col-xl-10 col-lg-8">
      <div
        className="testimonial-area position-relative mb-120"
        style={{ zIndex: "1", border: "2px solid #ae7e0d" }}
      >
        <div className="tournament-top-wrap">
          <div className="section-title tournament-title">
            <h2>
              <span> A MESSAGE FROM THE AUTHOR ... </span>
            </h2>
          </div>
        </div>
        <Slider className="testimonial-active slick" {...settings}>
          <div
            className="testimonial-item"
            style={{
              width: "100%",
              display: "inline-block",
              display: "flex",
              flexDirection: "column-reverse",
              gap: "3rem",
            }}
          >
            <div className="testimonial-content mb-50">
              <p>
                Hello There! Thank you for checking out the Battle Breakin'
                Crews website and purchasing the books. Releasing this story has
                truly been a labor of love. I started creating these characters
                in 2007 and finally completed the series in 2021. (Yep, that's a
                14-year journey!)
                <br />
                After going through a divorce in 2007, I found myself needing to
                establish new routines and a new Sunday tradition for my son and
                daughter. At that time, our church had a community center that
                offered free breakdance classes on Sundays. The breakdance class
                became something consistent we did on Sundays as we went through
                the process of rebuilding our lives. As I watched them dance, I
                would doodle in a notebook and begin to create breakdancing
                characters. Over a span of many Sundays, I witnessed my children
                regain their confidence and get their power back. I started
                adding superpowers to the characters to mirror the
                transformation I saw in my children. That's how the Battle
                Breakin' Crews story was born! <br />
                I hope that you enjoy this series. May you never give away your
                personal power and always make time to dance.
                <br />
                <br />
                Kind regards,
                <br />
                TaLisa
              </p>
              <a className="btn mt-20" href="#story">
                Experience the Book
              </a>
            </div>
            <div className="testimonial-avatar">
              <div className="testi-avtar-img">
                <img
                  src="https://img1.wsimg.com/isteam/ip/de6a5708-c1d5-4860-ba9a-f42314ecd97d/blob.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:400,h:400,cg:true/qt=q:18"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "100px",
                  }}
                  alt=""
                />
              </div>
              <div className="testi-avatar-info">
                <h6>TaLisa Sheppard </h6>
                <span>Author, Entrepreneur, Super Mom</span>
              </div>
            </div>
          </div>
        </Slider>
        <div className="testi-quote">
          <img src="assets/img/icon/testi_quote.png" alt="" />
        </div>
      </div>
      <div className="just-gamers-circle-shape">
        <img src="assets/img/images/gamers_circle_line.png" alt="" />
        <img
          src="assets/img/images/gamers_circle_shape.png"
          alt=""
          className="rotateme"
        />
      </div>
      <img
        src="assets/img/images/just_gamers_chart.png"
        alt=""
        className="gamers-chart-shape"
      />
    </div>
  );
}

export default TestimonialArea;
