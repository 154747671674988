import React, { useEffect } from "react";
import "magnific-popup";
import $ from "jquery";
import { Link } from "react-router-dom";
import about from "../../../assets/images/about.webp";
import "../../scss/about.scss";
function AboutUsArea() {
  useEffect(() => {
    $(".popup-video").magnificPopup({
      type: "iframe",
    });
  }, []);

  return (
    <section className="about-us-area about__ pt-90 pb-120" id="story">
      <div className="container container__">
        <div className="row grid__">
          <div className="start__ col-lg-7 order-0 order-lg-2 pl-40">
            <div className="section-title title-style-two mb-10">
              <span>about story</span>

              <h2 style={{ fontSize: "40px" }}>
                {" "}
                Welcome to the World of <span>
                  {" "}
                  Battle Breakin' Crews!
                </span>{" "}
              </h2>
            </div>
            <div className="about-content">
              <p>
                It's the year 2077 and the world of breaking has changed.
                Windmills turn to tornadoes. Coin drops become lethal. Toprock
                and fancy footwork shake the earth.
              </p>
              <p>
                The 7Forces Crew is the world's first breakdancing superheroes
                who go on an epic adventure to build the largest super crew to
                save the world from imminent danger.
              </p>
              <p>
                {" "}
                Readers can enjoy this epic adventure by purchasing a fantastic
                quasi-comic-style printed book or a Digital Art NFT book
                experience.
              </p>
              <div className="about-btn">
                <a
                  href="https://pmgpublishing.com/shop/ols/products/battle-breakin-crews-the-original-breakers"
                  target={"_blank"}
                  className="btn"
                  style={{ marginRight: "15px" }}
                >
                  Get the Book
                </a>
                <a href="#" className="btn">
                  Get the NFT Book Experience
                </a>
              </div>
            </div>
          </div>
          <div className="end__ col-lg-5">
            <div className="about-img">
              <img src={about} alt="" />

              <Link
                to="/https://www.youtube.com/watch?v=vZH0vZ83zy4"
                className="popup-video"
              >
                <img src="assets/img/icon/w_play.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUsArea;
