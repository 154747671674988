import React from "react";
import Slider from "react-slick";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import "../../scss/banner.scss";
import img from "../../../assets/images/digital marketing Agency banner template.png";
function SliderTwo() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    center: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          SlidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          sidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="slider-area banner slider-style-two" id="home">
      <div className="container__">
        <div className="grid__ ">
          <div className="start">
            <div className="slider-content ">
              <Fade up delay={0.4}>
                <h6>The Original Breakers</h6>
              </Fade>
              <Fade up delay={0.5}>
                <h2>
                  Battle <span>Breakin'</span> Crews
                </h2>
              </Fade>
              <Fade up delay={0.5}>
                <p>Super Dope Breakdancers with Powers. </p>
              </Fade>
              <Fade up delay={0.8}>
                <a href="#story" className="btn btn-style-two">
                  Join the Journey
                </a>
              </Fade>
            </div>
          </div>
          <div className="end">
            <img src={img} alt="" />
          </div>
          {/* <div className="slider-circle-shape">
            <img
              src="assets/img/slider/slider_circle.png"
              alt=""
              className="rotateme"
            />
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default SliderTwo;
