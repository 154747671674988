import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import kalaan from "../../../assets/images/videos/Kulaan.mp4";
import Blayzee from "../../../assets/images/videos/Blayzze.mp4";
import Boom from "../../../assets/images/videos/Boom.mp4";
import Emoni from "../../../assets/images/videos/Emoni.mp4";
import Helado from "../../../assets/images/videos/Helado.mp4";
import JDubz from "../../../assets/images/videos/J-Dubz.mp4";
import Theta1 from "../../../assets/images/videos/Theta1.mp4";

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fa fa-angle-right"></i>
    </button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fa fa-angle-left"></i>
    </button>
  );
}
const characters = [
  {
    name: "B-Boy Blayzze",
    powers: "Electricity Control ",
    amp: "Level 5 Breaker",
    story:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
    // img: Blayzze1,
    vid: Blayzee,
  },
  {
    name: "DJ Boom",
    powers: "Sound Absorption & Manipulation ",
    amp: "Level 6 EchoBreaker",
    story:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
    // img: Boom2,
    vid: Boom,
  },
  {
    name: "B-Girl Emoni",
    powers: " Fire Generation & Control ",
    amp: "  Level 2 Breaker",
    story:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
    // img: Emoni3,
    vid: Emoni,
  },
  {
    name: "B-Boy Helado",
    powers: " Water & Atmosphere Control   ",
    amp: " Level 4 Breaker",
    story:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
    // img: Helado4,
    vid: Helado,
  },

  {
    name: "B-Boy J-Dubz",
    powers: " Spatial Warp Generation  ",
    amp: "  Level 5 Breaker",
    story:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
    // img: Jay5,
    vid: JDubz,
  },
  {
    name: "B-Girl Kulaan",
    powers: "  Gravity Attacks & Constructs ",
    amp: " Level 3 Breaker ",
    story:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
    // img: Kulaan6,
    vid: kalaan,
  },
  {
    name: "B-Boy Theta1",
    powers: "  Air & Wind Manipulation",
    amp: " Level 6 Breaker ",
    story:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
    // img: Theta7,
    vid: Theta1,
  },
];
function GamesArea() {
  const vidRef = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    nav: true,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,

    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          Padding: "100px 50px",
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    vidRef.current.play();
  }, []);
  return (
    <div className="area-bg-one">
      {/* latest-games-area */}
      <section className="latest-games-area pt-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="section-title mb-50">
                {/* <span>The Best!</span> */}
                <h2>
                  Get the <span>7Forces Crew</span> NFT Cards
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider className="latest-games-active slick" {...settings}>
                {characters.map((item, index) => {
                  return (
                    <div className="latest-games-items mb-30">
                      <div className="latest-games-thumb">
                        <video
                          style={{ width: "100%" }}
                          src={item.vid}
                          ref={vidRef}
                          muted
                          autoPlay
                          loop
                          controls={false}
                          playsInline={true}
                        ></video>
                      </div>
                      <div className="latest-games-content">
                        <div className="lg-tag">
                          <a href="/#">racing</a>
                        </div>
                        <h4>
                          <a href="/#">
                            {item.name.split(" ").shift()}&nbsp;
                            <span>{item.name.split(" ").pop()}</span>
                          </a>
                        </h4>
                        <p>
                          Powers : <span>{item.powers}</span>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* latest-games-area-end */}
      {/* about-us-area */}

      {/* about-us-area-end */}
    </div>
  );
}

export default GamesArea;
