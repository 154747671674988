import React from 'react'
import IndexTwo from '../../components/index/indextwo/IndexTwo';

function HomeTwo() {
  return (
	<IndexTwo/>
  
  
  )
}

export default HomeTwo