import React from "react";
import Footer from "../../footer/Footer";
import HeaderTwo from "../../header/HeaderTwo";
import HeaderThree from "../../header/HeaderThree";
import HeaderFour from "../../header/HeaderFour";
import SliderTwo from "./SliderTwo";
import NewGamesArea from "./NewGamesArea";
import GamesAreatwo from "./GamesAreatwo";
import GamesArea from "./GamesArea";
import CtaArea from "./CtaArea";
import ShopAreaTwo from "./ShopAreaTwo";
import BlogArea from "./BlogArea";
import AboutUsArea from "../indexone/AboutUsArea";
import HomeFour from "../indexfour/HomeFour";
import ShopArea from "../indexone/ShopArea";
import FooterThree from "../../footer/FooterThree";
import FooterFive from "../../footer/FooterFive";
import FooterSix from "../../footer/FooterSix";
import TrendingGame from "../indexfive/TrendingGame";

function IndexTwo() {
  return (
    <>
      {/* header-area */}
      {/* <HeaderTwo/> */}
      {/* <HeaderThree/> */}
      <HeaderFour />

      {/* main-area */}
      <main>
        {/* slider-area */}
        <SliderTwo />
        <AboutUsArea />
        {/* slider-area-end */}
        {/* new-games-area */}
        <NewGamesArea />
        <TrendingGame />
        <HomeFour />
        {/* new-games-area-end */}
        {/* gamers-area */}
        {/* <GamesAreatwo/> */}

        {/* gamers-area-end */}
        {/* featured-game-area */}
        {/* <GamesArea/> */}
        {/* featured-game-area-end */}
        {/* cta-area */}
        {/* <CtaArea/> */}
        {/* cta-area-end */}
        {/* shop-area */}
        {/* <ShopAreaTwo/> */}
        {/* shop-area-end */}
        {/* blog-area */}
        <ShopArea />
        {/* blog-area-end */}
      </main>
      {/* main-area-end */}
      {/* <Footer/> */}
      <FooterThree />
      {/* <FooterFive/>
        <FooterSix/> */}
    </>
  );
}

export default IndexTwo;
