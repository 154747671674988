import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { FaTiktok } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";
import $ from "jquery";

const HeaderFour = () => {
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .push-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );

      $(".menu-backdrop, .mobile-menu .close-btn").click(() => {
        $("body").removeClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, []);
  useEffect(() => {
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
      }
    });
  }, []);

  return (
    <header className="header-style-four">
      <div className="header-top-area s-header-top-area d-none d-lg-block">
        <div className="container custom-container-two">
          <div className="row align-items-center">
            <div className="col-lg-6 d-none d-lg-block">
              <div className="header-top-offer">
                {/* <p>Exclusive Black Friday ! Offer</p> */}
                <span className="coming-time" data-countdown="2022/3/15" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-social">
                <span>Follow us on :</span>
                <ul>
                  <li>
                    <a
                      href=" https://www.youtube.com/@battlebreakincrews4784/featured"
                      className="youtube"
                    >
                      <AiFillYoutube />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/@battlebreakincrews?lang=en"
                      className="tiktok"
                    >
                      <FaTiktok />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="sticky-header"
        className="header-four-wrap main-header menu-area"
      >
        <div className="container custom-container-two">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars" />
              </div>
              <div className="main-menu menu-style-two">
                <nav>
                  <div className="logo">
                    <a href="/index-4">
                      <img src={logo} className="nav__logo__" alt="Logo" />
                    </a>
                  </div>
                  <div
                    id="mobile-menu"
                    className="navbar-wrap push-menu d-none d-lg-flex"
                  >
                    <ul className="navigation">
                      <li className=" menu-item-has-children">
                        <a href="#home">Home</a>
                      </li>
                      <li className=" menu-item-has-children">
                        <a href="#story">Story</a>
                      </li>
                      <li>
                        <a href="#collection">Collection</a>
                      </li>
                      <li>
                        <a href="#media">Media</a>
                      </li>
                      <li>
                        <a href="#card">NFT Cards</a>
                      </li>
                      <li className=" menu-item-has-children">
                        <a href="#auther">Author</a>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="header-action">
                  <ul>
                    <li className="header-shop-cart"><a href="/#"><i className="fas fa-shopping-basket" /><span>0</span></a>
                      <ul className="minicart">
                        <li className="d-flex align-items-start">
                          <div className="cart-img">
                            <a href="/#">
                              <img src="assets/img/product/cart_p01.jpg" alt="" />
                            </a>
                          </div>
                          <div className="cart-content">
                            <h4>
                              <a href="/#">Xbox One Controller</a>
                            </h4>
                            <div className="cart-price">
                              <span className="new">$229.9</span>
                              <span>
                                <del>$229.9</del>
                              </span>
                            </div>
                          </div>
                          <div className="del-icon">
                            <a href="/#">
                              <i className="far fa-trash-alt" />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="total-price">
                            <span className="f-left">Total:</span>
                            <span className="f-right">$239.9</span>
                          </div>
                        </li>
                        <li>
                          <div className="checkout-link">
                            <a href="/cart">Shopping Cart</a>
                            <a className="red-color" href="/checkout">Checkout</a>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li className="header-search"><a href="/#" data-toggle="modal" data-target="#search-modal"><i className="fas fa-search" /></a></li>
                  </ul>
                </div> */}
                </nav>
              </div>
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <Link to="/">
                      <img
                        src={logo}
                        alt=""
                        style={{ width: "70px" }}
                        title="true"
                      />
                    </Link>
                  </div>
                  <div className="menu-outer"></div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <a
                          href=" https://www.youtube.com/@battlebreakincrews4784/featured"
                          className="youtube"
                        >
                          <AiFillYoutube />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.tiktok.com/@battlebreakincrews?lang=en"
                          className="tiktok"
                        >
                          <FaTiktok />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
            {/* Modal Search */}
            <div
              className="modal fade"
              id="search-modal"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <form>
                    <input type="text" placeholder="Search here..." />
                    <button>
                      <i className="fa fa-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderFour;
