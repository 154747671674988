import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Blayzze1 from "../../../assets/images/characters/Blayzze.jpg";
import Boom2 from "../../../assets/images/characters/Boom.jpg";
import Emoni3 from "../../../assets/images/characters/Emoni.jpg";
import Helado4 from "../../../assets/images/characters/Helado.jpg";
import Jay5 from "../../../assets/images/characters/Jay.jpg";
import Kulaan6 from "../../../assets/images/characters/Kulaan.jpg";
import Theta7 from "../../../assets/images/characters/Theta1.jpg";
import Slider from "react-slick";
function NewGamesArea() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [stringg, setstringg] = useState("");
  let slider1, slider2;

  const characters = [
    {
      name: "B-Boy Blayzze",
      powers: "Electricity Control ",
      amp: "Level 5 Breaker",
      story:
        "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
      img: Blayzze1,
    },
    {
      name: "DJ Boom",
      powers: "Sound Absorption & Manipulation ",
      amp: "Level 6 EchoBreaker",
      story:
        "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
      img: Boom2,
    },
    {
      name: "B-Girl Emoni",
      powers: " Fire Generation & Control ",
      amp: "  Level 2 Breaker",
      story:
        "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
      img: Emoni3,
    },
    {
      name: "B-Boy Helado",
      powers: " Water & Atmosphere Control   ",
      amp: " Level 4 Breaker",
      story:
        "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
      img: Helado4,
    },

    {
      name: "B-Boy J-Dubz",
      powers: " Spatial Warp Generation  ",
      amp: "  Level 5 Breaker",
      story:
        "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
      img: Jay5,
    },
    {
      name: "B-Girl Kulaan",
      powers: "  Gravity Attacks & Constructs ",
      amp: " Level 3 Breaker ",
      story:
        "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
      img: Kulaan6,
    },
    {
      name: "B-Boy Theta1",
      powers: "  Air & Wind Manipulation",
      amp: " Level 6 Breaker ",
      story:
        "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam esse veritatis dolores ea, exercitationem adipisci quisquam quo totam quasi fugiat distinctio sequi eius molestias reprehenderit eos libero doloremque quam expedita!",
      img: Theta7,
    },
  ];

  useEffect(
    function () {
      setNav1(slider1);
      setNav2(slider2);
    },
    [slider1, slider2]
  );

  return (
    <section
      className="released-games-area gray-bg pt-90 pb-90"
      style={{ background: "#24252f", overflow: "hidden" }}
      id="collection"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-three text-center mb-20">
              <h2>
                MEET THE 7FORCES <span> CREW</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xl-8 col-lg-12">
            <Slider
              className="released-game-active"
              asNavFor={nav2}
              ref={(slider) => (slider1 = slider)}
              arrows={false}
            >
              {characters.map((item, ind) => {
                return (
                  <div className="released-game-carousel">
                    <div className="released-game-item">
                      <div className="released-game-item-bg" />
                      <div className="released-game-img">
                        <img src={item.img} style={{ width: "300px" }} alt="" />
                      </div>
                      <div className="released-game-content">
                        <div className="released-game-rating">
                          {/* <h5>Rating :</h5> */}
                          <div className="released-rating">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                          </div>
                        </div>
                        <h4>
                          {item.name.split(" ").shift()}&nbsp;
                          <span>{item.name.split(" ").pop()}</span>
                        </h4>
                        <div className="released-game-list mb-15">
                          <ul>
                            <li>
                              <span>AMP :</span>
                              {item.amp}
                            </li>
                            <li>
                              <span>Crew :</span>7Forces
                            </li>
                            <li>
                              <span>Powers :</span>
                              {item.powers}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="col-xl-4 col-md-2 col-lg-12">
            <Slider
              className="released-game-nav"
              asNavFor={nav1}
              swipeToSlide={true}
              focusOnSelect={true}
              ref={(slider) => (slider2 = slider)}
              slidesToShow={3}
              vertical={true}
              autoplay={true}
              arrows={false}
            >
              {characters.map((item, ind) => {
                return (
                  <div className="released-game-nav-item">
                    <img src={`/images/characters/${ind + 1}.jpg`} alt="" />
                  </div>
                );
              })}

              {/* <div className="released-game-nav-item">
                <img src="assets/img/images/release_game_nav02.jpg" alt="" />
              </div>
              <div className="released-game-nav-item">
                <img src="assets/img/images/release_game_nav03.jpg" alt="" />
              </div>
              <div className="released-game-nav-item">
                <img src="assets/img/images/release_game_nav02.jpg" alt="" />
              </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewGamesArea;
