import React from 'react';
import Slider from 'react-slick';
import TestimonialArea from './TestimonialArea';


function PrevArrow(props){
  const {className,onClick} = props;
  return(
    <button type='button' className ={ className } onClick={ onClick }><i className="fa fa-angle-left"></i></button>
  );
}
function NextArrow(props){
  const {className,onClick} = props;
  return(
    <button type='button' className ={ className } onClick={ onClick }><i className="fa fa-angle-right"></i></button>
  );
}

export default function ShopArea() {
		const settings = {

  dots: false,
  margin:5,
  infinite: true,
  speed: 1000,
  autoplay: false,
  arrows: true,
	prevArrow: <PrevArrow/>,
	nextArrow: <NextArrow/>,
	 slidesToShow: 4,
  slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
			     slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
			slidesToShow: 2,
        slidesToScroll: 1
			}
		},
		{
			breakpoint: 767,
			settings: {
			  slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
			}
		},
		{
			breakpoint: 575,
			settings: {
				 slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
			}
		},
	]
  }
  return (
	   <div className="area-bg-two" id='auther'>
        {/* shop-area */}
        <section className="shop-area pt-120 ">
          <div className="container">
    
          </div>
        </section>
        {/* shop-area-end */}


        {/* blog-testimonial-area */}
        <section className="blog-and-testimonial">
          <div className="container">
            <div className="row justify-content-center">
           
              {/* testomnial area */}
              <TestimonialArea/>
            </div>
          </div>
        </section>
        {/* blog-testimonial-area-end */}
      </div>
  )
}
