import React,{ useEffect } from 'react'
import $ from "jquery"
import 'magnific-popup';
import GamesArea from '../indexone/GamesArea';
import live from "../../../assets/images/maxresdefault.jpg"
const HomeFour = () => {


    useEffect(()=>{

		$('.popup-video').magnificPopup({
			type: 'iframe'
		});
	},[])



  return (
    <div className="home-four-area-bg" id='card' style={{overflow:'hidden'}}>
    <div className="bg" />
    {/* latest-games-area */}
        <GamesArea/>
    {/* latest-games-area-end */}
    {/* live-match-area */}
    <section className="live-match-area pt-90 pb-150 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="section-title home-four-title text-center mb-60">
              <h2>Check Out The Audio Book on  <span> YouTube!</span></h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <div className="live-match-wrap">
              <img src={live} alt="" />
              <a href="https://www.youtube.com/watch?v=WOrB1vPSsxQ" className="popup-video"><img src="assets/img/icon/video_play_icon.png" alt="" /></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* live-match-area-end */}
    {/* live-match-team-area */}
  
    {/* live-match-team-area-end */}
  </div>
  )
}

export default HomeFour